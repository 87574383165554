import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setLayoutConfigrations } from './stores/Layout/layoutActions';
import { fetchMenu } from 'stores/Menu/menuActions';
import Translation from 'services/translation.service';
import { initiateUser } from 'services/user.service';
import HTTP from 'services/http.service';
import { setStorageElement } from 'services/storage.service';
import {
  LAYOUT_DEFAULT,
  GET_URL_PATH_FIRST_PART,
  LAYOUT_WITH_HEADER_FOOTER,
} from './constants';
import { getPath } from './util';
import { setPageContentEmpty } from 'stores/Layout/layoutActions';
import {
  fetchHomepageSlider,
  fetchHomepagePackages,
  fetchHomepageSections,
} from 'stores/Homepage/homepageActions';

class AppInitialize extends React.Component {
  constructor(props) {
    super(props);
    const { history } = props;

    /* 
      when user click on browser back btn,
      we check the new page if it has different language then
      we call setLayoutConfigrations to fetch routs, translation and app config
    */
    history.listen((newLocation, action) => {
      this.props.setPageContentEmpty();
      if (
        action === 'POP' &&
        this.props.currentRoute &&
        this.props.currentRoute.routeLang &&
        Translation.getLanguage(newLocation.pathname) !== this.props.lang
      ) {
        this.props.setLayoutConfigrations(
          Translation.getLanguage(newLocation.pathname)
        );
      }
    });
    // this.props.setLayoutConfigrations(Translation.getLanguage(window.location.pathname));
  }

  initialCalles = (lang) => {
    if (window.appLang === undefined) {
      window.appLang = lang;
      this.props.setLayoutConfigrations(lang)
    }
  };

  initialDevice = (layout, lang) => {
    return initiateUser()
      .then((response) => {
        if (response) {
          HTTP.set_session(response.data['auth_token']);
          setStorageElement('authToken', response.data['auth_token']);
        }
        if (layout === LAYOUT_DEFAULT) {
          this.initialCalles(lang);
        }
        return new Promise((resolve) => {
          resolve(true);
        });
      })
      .catch((error) => {
        return new Promise((resolve, reject) => {
          reject(false);
        });
      });
  };


  componentDidMount() {
    const lang = Translation.getLanguage(this.props.location.pathname);
    let layout = LAYOUT_DEFAULT;
    const path = getPath(this.props.location.pathname, GET_URL_PATH_FIRST_PART);
    if (path === 'hawakom-faq') {
      // the path of hawakom faq template => this path will called inside mobile app only
      layout = LAYOUT_WITH_HEADER_FOOTER;
    }
    this.setState({
      layout: layout,
    });
    this.initialDevice(layout, lang);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.lang !== this.props.lang &&
      this?.state?.layout === LAYOUT_DEFAULT
    ) {
      prevProps.lang && this.initialCalles();
    }
  }

  shouldComponentUpdate(prevProps) {
    return prevProps.routes !== this.props.routes;
  }

  render() {
    console.log(
      '%cApp Initializer',
      'color: Yellow;background:red; font-size:16px;padding:0 10px'
    );

    return this.props.children;
  }
}

const mapStateToProps = (state) => {
  return {
    currentRoute: state.layout.currentRoute,
    lang: state.layout.iso,
    routes: state.layout.routes,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setLayoutConfigrations,
      fetchMenu,
      setPageContentEmpty,
      fetchHomepageSlider,
      fetchHomepagePackages,
      fetchHomepageSections,
    },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppInitialize));
