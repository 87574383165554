import CryptoJS from 'crypto-js';
import Settings from './settings.service';

export const removeStorageElement = (key, location = 'local') => {
  if (location === 'session') {
    sessionStorage.removeItem(key);
    return;
  }
  localStorage.removeItem(key);
};

export const clearStorageElements = () => {
  sessionStorage.clear();
  localStorage.clear();
};

export const setStorageElement = (key, value, location = 'local') => {
  const salt = CryptoJS.lib.WordArray.random(128 / 8); // Generate a random salt (128 bits)
  const content = JSON.stringify(value);
  if (location === 'session') {
    sessionStorage.setItem(key, content);
    return;
  }
  let newData = null;
  if (typeof JSON.parse(content) ==='object') {
    newData = content;
    const encryptedData = CryptoJS.AES.encrypt(
      newData,
      Settings.REACT_APP_SECRET_KEY,
      { iv: salt }
    ).toString();
    localStorage.setItem(key, encryptedData);
  } else {
    newData = content.replaceAll('"', '');
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(newData),
      Settings.REACT_APP_SECRET_KEY,
      { iv: salt }
    ).toString();
    localStorage.setItem(key, encryptedData);
  }
  console.log('newData :>> ', newData);

  // const encryptedData = CryptoJS.AES.encrypt(
  //   JSON.stringify(newData),
  //   Settings.REACT_APP_SECRET_KEY,
  //   { iv: salt }
  // ).toString();
  // localStorage.setItem(key, encryptedData);
};

export const getStorageElement = (key, location = 'local') => {
  if (location === 'session') {
    return JSON.parse(sessionStorage.getItem(key));
  }
  const salt = CryptoJS.lib.WordArray.random(128 / 8); // Generate a random salt (128 bits)
  try {
    const getData = localStorage.getItem(key);
    if (!getData) {
      return null; // Handle the case where no data is stored.
    }
    const bytes = CryptoJS.AES.decrypt(getData, Settings.REACT_APP_SECRET_KEY, {
      iv: salt,
    });
    if (!bytes || !bytes.sigBytes) {
      return JSON.parse(getData); // Handle empty data
    }
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    if (!decryptedData) {
      // if item decrypt by default return get data
      return JSON.parse(getData);
    }
    const parsedData = JSON.parse(decryptedData);
    return parsedData;
  } catch (error) {
    console.log('error getStorageElement:>> ', error);
    // throw error; // Re-throwing the error for further handling, if necessary.
  }
};
