export const TRANSACTION_ID = 'transaction_id';
export const STORES_TYPE_LIST = 'list';
export const STORES_TYPE_MAP = 'map';
export const ORDER_ID = 'order_id';
export const SIM_ORDER = 'SIM_ORDER';
export const SIM_ORDER_ID = 'sim_order_id';
export const ESIM_ORDER_ID = 'esim_order_id';
export const ICCID = 'ICCID';
export const PUK = 'puk';
export const ID_NUMBER = 'idNumber';
export const API_VERSION_V3 = 'v3';

export const BUNDLES_CATEGORY_INTERNATIONAL_PASS = 'intl-pass';
export const BUNDLES_CATEGORY_LOCAL_PASS = 'local-pass';
export const BUNDLES_CATEGORY_MOBILE_DATA = 'mobile-data';
export const BUNDLES_CATEGORY_LATEST = 'latest-bundle';
export const BUNDLES_CATEGORY_OFFERS4U = 'offers-for-you';
export const BUNDLES_CATEGORY_HAWAKOM = 'hawakom-bundle';

export const PACKAGE_CATEGORY_LEBARA_POSTPAID = 'lebara-postpaid';
export const PACKAGE_CATEGORY_POSTPAID = 'postpaid';
export const PACKAGE_CATEGORY_DATA_SIM = 'data-sim';
export const PACKAGE_CATEGORY_PREPAID = 'prepaid';
export const PACKAGE_CATEGORY_HAWAKOM = 'hawakom';
export const PACKAGE_CATEGORY_NASSR = 'nassr';
export const PACKAGE_CATEGORY_SEC = 'sec';
export const PACKAGE_CATEGORY_ESIM = 'lebara-esim';
export const PACKAGE_CATEGORY_LEBARA_PREPAID = 'lebara-prepaid';

export const LINE_TYPE_DATA = 'data';

export const PAGE_ROUTE_BUNDLE = 'BundleHub';
export const PAGE_ROUTE_PACKAGE = 'PackageHub';

export const INITIATE_PLAN_INFO = 'INITIATE_PLAN_INFO';
export const CHECK_ELIGIBILITY_START = 'CHECK_ELIGIBILITY_START';
export const CHECK_ELIGIBILITY_SUCCESS = 'CHECK_ELIGIBILITY_SUCCESS';
export const CHECK_ELIGIBILITY_FAILED = 'CHECK_ELIGIBILITY_FAILED';
export const PAYMENT_DETAILS = 'PAYMENT_DETAILS';
export const VALIDATE_OTP = 'VALIDATE_OTP';
export const PLAN_SUMMARY = 'PLAN_SUMMARY';
export const PAYMENT = 'PAYMENT';
export const FINISHED_STEP = 'FINISHED_STEP';
export const PAYMENT_FAILED = 'PAYMENT_FAILED';
export const NOT_ELIGIBLE = 'NOT_ELIGIBLE';
export const PAYMENT_FINISHED = 'PAYMENT_FINISHED';

export const INITIATE_CONTACTUS = 'INITIATE_CONTACTUS';
export const SET_CONTACTUS_INFO = 'SET_CONTACTUS_INFO';
export const SET_CONTACTUS_FAILED = 'SET_CONTACTUS_FAILED';

export const PLAN = 'PLAN';
export const PLAN_BREAKS = 'PLAN_BREAKS';
export const AUTHINTICATION = 'AUTHINTICATION';
export const VERIFYING = 'VERIFYING';
export const ADD_ACCOUNT = 'ADD_ACCOUNT';
export const DIRECT_LOGIN = 'DIRECT_LOGIN';
export const INTERNATIONAL_TRANSFER = 'INTERNATIONAL_TRANSFER';

export const START = 'START';
export const SUCCESS = 'SUCCESS';
export const FAILED = 'FAILED';
export const CONFIRMATION = 'CONFIRMATION';

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const START_SUBSCRIPTION = 'START_SUBSCRIPTION';
export const FAILED_SUBSCRIPTION = 'FAILED_SUBSCRIPTION';
export const SUCCESS_SUBSCRIPTION = 'SUCCESS_SUBSCRIPTION';

export const LOADING = 'LOADING';
export const TRY_AGAIN = 'TRY_AGAIN';

export const START_FETCHING_SINGLE_OFFER = 'START_FETCHING_SINGLE_OFFER';
export const FAILED_FETCHING_SINGLE_OFFER = 'FAILED_FETCHING_SINGLE_OFFER';
export const SUCCESS_FETCHING_SINGLE_OFFER = 'SUCCESS_FETCHING_SINGLE_OFFER';

export const REQUEST_OTP_START = 'REQUEST_OTP_START';
export const REQUEST_OTP_SUCCESS = 'REQUEST_OTP_SUCCESS';
export const REQUEST_OTP_FAILED = 'REQUEST_OTP_FAILED';

export const VALIDATE_ID_START = 'VALIDATE_ID_START';
export const VALIDATE_ID_SUCCESS = 'VALIDATE_ID_SUCCESS';
export const VALIDATE_ID_FAILED = 'VALIDATE_ID_FAILED';

export const VALIDATE_OTP_SUCCESS = 'VALIDATE_OTP_SUCCESS';

export const SET_ACTIVE_BUNDLE_CATEGORY = 'SET_ACTIVE_BUNDLE_CATEGORY';
export const FETCH_ACTIVE_BUNDLE_CATEGORY = 'FETCH_ACTIVE_BUNDLE_CATEGORY';
export const ASSIGN_ACTIVE_BUNDLE_CATEGORY = 'ASSIGN_ACTIVE_BUNDLE_CATEGORY';
export const SET_BUNDLES = 'SET_BUNDLES';
export const FETCH_BUNDLES = 'FETCH_BUNDLES';

export const BUYING_NUMBER = 'BUYING_NUMBER';

export const MINUTES = 'minutes';

export const SELECT_PLAN = 'SELECT_PLAN';
export const SHOW_PAYMENT = 'SHOW_PAYMENT';

export const COMMIT_PAYMENT_START = 'COMMIT_PAYMENT_START';
export const COMMIT_PAYMENT_SUCCESS = 'COMMIT_PAYMENT_SUCCESS';
export const COMMIT_PAYMENT_FAILED = 'COMMIT_PAYMENT_FAILED';

export const CUSTOM_BUNDLES_START = 'CUSTOM_BUNDLES_START';
export const CUSTOM_BUNDLES_SUCCESS = 'CUSTOM_BUNDLES_SUCCESS';
export const CUSTOM_BUNDLES_FAILED = 'CUSTOM_BUNDLES_FAILED';

export const UPDATE_CALCULATIONS = 'UPDATE_CALCULATIONS';

export const PURCHASE_BUNDLE_START = 'PURCHASE_BUNDLE_START';
export const PURCHASE_BUNDLE_SUCCESS = 'PURCHASE_BUNDLE_SUCCESS';
export const PURCHASE_BUNDLE_FAILED = 'PURCHASE_BUNDLE_FAILED';

export const PREPAID = 'prepaid';
export const POSTPAID = 'postpaid';

export const PLAN_STATUS_ACTIVE = 'active';
export const PLAN_STATUS_STOPPED = 'stopped';
export const PLAN_STATUS_EXPIRED = 'expired';
export const PLAN_STATUS_IN_TRANSIT = 'in_transit';

export const MAIN = 'main';

export const DASHBBOARD_PAGE_USAGE = 'usage';
export const DASHBBOARD_PAGE_ADDONS = 'addons';
export const DASHBBOARD_PAGE_ADD_ACOUNT = 'add-account';
export const DASHBBOARD_PAGE_MANAGE_ACCOUNT = 'manage-account';
export const DASHBBOARD_PAGE_SWITCH_PLAN = 'switch-plan';
export const DASHBBOARD_PAGE_CUSTOMIZE_BUNDLE = 'customize-bundle';
export const DASHBBOARD_PAGE_CREDIT_TRANSFER = 'credit-transfer';
export const DASHBBOARD_PAGE_PROFILE = 'profile';

export const SWITCH_PLAN_STEP_ELIGIBILE_PLANS = 'eligible-plans';
export const SWITCH_PLAN_STEP_SUMMARY = 'summary';
export const SWITCH_PLAN_STEP_PAYMENT = 'payment';

export const KEY_ENTER_BUTTON = 'Enter';

export const SCREEN_VALIDATE_OTP_CODE = 'validateOtpCode';
export const SCREEN_REGISTRATION_TYPE_STEP = 'registerTypeStep';
export const SCREEN_REQUEST_OTP_CODE = 'requestOtpCode';
export const SCREEN_SIGNUP = 'signup';
export const SCREEN_DONE = 'done';

export const CONDITIONS_MATCH_ANY = 'any';
export const CONDITIONS_MATCH_ALL = 'all';

export const SUBSCRIPER_TYPE_POSTPAID = 'postpaid';
export const SUBSCRIPER_TYPE_PREPAID = 'prepaid';

export const DELETE_ACCOUNT_CONFIRMATION = 'DELETE_ACCOUNT_CONFIRMATION';

export const DELETE_ACCOUNT_START = 'DELETE_ACCOUNT_START';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAILED = 'DELETE_ACCOUNT_FAILED';

export const BALANCE_LABEL = 'Balance';
export const DUE_BALANCE_LABEL = 'DueBalance';

export const ADD_ACCOUNT_INFO_SCREEN = 'account-info';
export const ADD_ACCOUNT_OTP_SCREEN = 'otp-step';
export const ADD_ACCOUNT_VALIDATE_ID_SCREEN = 'validate-id';
export const ADD_ACCOUNT_RESULT_SCREEN = 'result';


export const DEACTIVATE_NUMBER_CONFIRM_SCREEN = 'confirm-info';
export const DEACTIVATE_NUMBER_REASON_SCREEN = 'reason-step';
export const DEACTIVATE_NUMBER_VALIDATE_OTP_SCREEN = 'validate-otp';
export const DEACTIVATE_NUMBER_RESULT_SCREEN = 'result';
export const CHOOSE_REASON = 'Choose_Reason';
export const GET_REASON = 'get_reason';
export const GET_REASON_SUCCESS = 'get_reason_success';
export const GET_REASON_FAILED = 'get_reason_failed';
export const DEACTIVATE_SUCCESS = 'deactivate_failed';


export const LOGIN_INPUT_TYPE_PHONE = 'phone'
export const LOGIN_INPUT_TYPE_EMAIL = 'email'
export const LOGIN_INPUT_TYPE_DATA_SIM = 'data-sim'


export const GA_TAGS_PAGE_LOAD_DATA_NET_SAVER = {
  en: 'DC-9509201/lp/phd_d00c+standard',
  ar: 'DC-9509201/lp/phd_d00b+standard',
};
export const GA_TAGS_PAGE_SHOP = {
  en: 'DC-9509201/lp/phd_d00a+standard',
  ar: 'DC-9509201/lp/phd_d00-+standard',
};

export const ICONS_LOCAL_MIN = 'local_min';
export const ICONS_DATA = 'data';
export const ICONS_SOCIAL_MEDIA = 'social_media';
export const ICONS_SMS = 'sms';
export const ICONS_WIFI_DATA = 'wifi_data';
export const ICONS_ROAMING_DATA = 'roaming_data';
export const ICONS_INTERNATIONAL_MIN = 'int_min';
export const ICONS_ROAMING_MIN = 'roaming_min';
export const ICONS_MULTI_SIM = 'multi_sim';
export const ICONS_PHONE = 'phone24';
export const ICONS_ALLOWANCE = 'allowance';
export const ICONS_SOCIAL_PERSON = 'social_person';
export const ICONS_EMAIL = 'email';

export const LOCAL_STORAGE = 'local';

export const MOVE_OUT_ELEMENT = 'out';

export const CREDIT_TRANSFER_LOCAL = 'local';
export const CREDIT_TRANSFER_INTERNATIONAL = 'international';
export const CREDIT_TRANSFER_DATA = 'data';

export const TRANSFER_LOCAL_CONFIRMATION = 'TRANSFER_LOCAL_CONFIRMATION';
export const TRANSFER_LOCAL_START = 'TRANSFER_LOCAL_START';
export const TRANSFER_LOCAL_FAILED = 'TRANSFER_LOCAL_FAILED';
export const TRANSFER_LOCAL_SUCCESS = 'TRANSFER_LOCAL_SUCCESS';

export const HYPERPAY_PAYMENT_MADA = 'MADA';
export const HYPERPAY_PAYMENT_VISA = 'VISA';
export const HYPERPAY_PAYMENT_MASTER = 'MASTER';

export const INIT_TRANSFER_DATA = 'INIT_TRANSFER_DATA';
export const INIT_TRANSFER_DATA_SUCCESS = 'INIT_TRANSFER_DATA_SUCCESS';
export const INIT_TRANSFER_DATA_FAILED = 'INIT_TRANSFER_DATA_FAILED';

export const SET_ACTIVE_DENOMINATION = 'SET_ACTIVE_DENOMINATION';
export const SET_SELECTED_WALLET = 'SET_SELECTED_WALLET';
export const RESET_DATA_TRANSFER = 'RESET_DATA_TRANSFER';

export const COUNTRY_KSA_ISO = 'COUNTRY_KSA_ISO';

export const RECIPIENT_PRODUCT_SERVICE_TYPE_BUNDLES = 'bundles';
export const RECIPIENT_PRODUCT_SERVICE_TYPE_AIRTIME = 'airtime';

export const INTERNATIONAL_TRANSFER_STEP_SELECT_PRODUCT =
  'INTERNATIONAL_TRANSFER_STEP_SELECT_PRODUCT';
export const INTERNATIONAL_TRANSFER_STEP_OTP =
  'INTERNATIONAL_TRANSFER_STEP_OTP';
export const INTERNATIONAL_TRANSFER_STEP_DONE =
  'INTERNATIONAL_TRANSFER_STEP_DONE';

export const INTERNATIONAL_TRANSFER_SET_OTP = 'INTERNATIONAL_TRANSFER_SET_OTP';
export const INTERNATIONAL_TRANSFER_SET_PRODUCT =
  'INTERNATIONAL_TRANSFER_SET_PRODUCT';
export const INTERNATIONAL_TRANSFER_UPDATE_STEP =
  'INTERNATIONAL_TRANSFER_UPDATE_STEP';
export const INTERNATIONAL_TRANSFER_RESET = 'INTERNATIONAL_TRANSFER_RESET';

export const ORDER_SIM_STEP_NUMBER_SELECTION = 'number-selection';
export const ORDER_SIM_STEP_MNP_NUMBER_SELECTION = 'mnp-number-selection';
export const ORDER_SIM_STEP_PLAN_SELECTION = 'plan-selection';
export const ORDER_SIM_STEP_USER_INFORMATION = 'user-information';
export const ORDER_SIM_STEP_REFERAL = 'referral';
export const ORDER_SIM_STEP_SIM_REPLACEMENT_REQUEST = 'sim-replacement-request';
export const ORDER_SIM_STEP_ELIGIBILITY_CHECK = 'eligibility-check';
export const ORDER_SIM_STEP_DELIVERY = 'delivery';
export const ORDER_SIM_STEP_ORDER_CONFIRMATION = 'order-confirmation';
export const ORDER_SIM_STEP_PAYMENT = 'payment';
export const ORDER_SIM_STEP_PAYMENT_DETAILS = 'payment-details';
export const ORDER_SIM_STEP_ORDER_DETAILS = 'order-details';

export const GIFT_CARD_CATEGORY = 'gift-cards';
export const PRODUCTS_CATEGORY = 'products';
export const ELLIPSES_PROGRESS_SOURCE_USAGE = 'usage';
export const SHOP_CREATE_ORDER = 'create-order';
export const SHOP_VERIFY_USER = 'user-verification';
export const SHOP_ORDER_VERIFY = 'verify';

export const SHOP_ORDER_SUMMARY = 'SHOP_ORDER_SUMMARY';
export const SHOP_ORDER_PAYMENT = 'SHOP_ORDER_PAYMENT';
export const TRANSACTION_TYPE_ORDER_PRODUCT = 'order-product';

export const SHOP_BOX_FLOW_REFERAL = 'referral';
export const SHOP_BOX_FLOW_HAWAKOM = 'hawakom';
export const SHOP_BOX_FLOW_NEW_CUSTOMER = 'newCustomer';
export const SHOP_BOX_FLOW_SHOP = 'shop';
export const SHOP_BOX_FLOW_NASSR = 'nassr';
export const SHOP_BOX_FLOW_SEC = 'sec';

export const BUYING_PROCESS_TYPE_BUYING = 'buying';
export const BUYING_PROCESS_TYPE_SWITCH_PLAN = 'switchPlan';

export const SIM_TYPE_SIM = 'sim';
export const SIM_TYPE_ESIM = 'esim';

export const EMIAL_TRANSITION_FLOW_SIGN_UP = 'EMIAL_TRANSITION_FLOW_SIGN_UP';
export const EMIAL_TRANSITION_FLOW_EMAIL_VERIFICATION =
  'EMIAL_TRANSITION_FLOW_EMAIL_VERIFICATION';

export const VERIFICATION_STEP_ACTIVATE_EMAIL =
  'VERIFICATION_STEP_ACTIVATE_EMAIL';
export const VERIFICATION_STEP_VERIFY_EMAIL = 'VERIFICATION_STEP_VERIFY_EMAIL';
export const VERIFICATION_STEP_VERIFY_DONE = 'VERIFICATION_STEP_VERIFY_DONE';

export const EMAIL_OTP_WINDOW = 'email_otp_window';

export const DASHBOARD_PAGE_USAGE = 'usage';

export const OTP_TYPE_EMAIL = 'OTP_TYPE_EMAIL';

export const EMAIL_VERIFICATION_SLIDER_IMAGE = 'referral-banner';
export const WIDGET_RECHARGE_METER = 'recharge-meter';
export const WIDGET_VAS = 'services';

export const UPDATE_EMAIL_FLOW_ACTIVATION = 'activate-email';
export const UPDATE_EMAIL_FLOW_CHANGE_EMAIL = 'change-email';

export const GET_SLIDER_IMAGE_START = 'GET_SLIDER_IMAGE_START';
export const GET_SLIDER_IMAGE_SUCCESS = 'GET_SLIDER_IMAGE_SUCCESS';
export const GET_SLIDER_IMAGE_FAILED = 'GET_SLIDER_IMAGE_FAILED';

export const SET_NUMBER_SETTINGS = 'SET_NUMBER_SETTINGS';
export const GET_NUMBER_SETTINGS = 'GET_NUMBER_SETTINGS';
export const FAILED_NUMBER_SETTINGS = 'FAILED_NUMBER_SETTINGS';

export const POPUP_COMMUNICATION_LANG = 'POPUP_COMMUNICATION_LANG';
export const POPUP_SERVICE = 'POPUP_SERVICE';
export const POPUP_SIM_INFORMATION = 'POPUP_SIM_INFORMATION';
export const SET_NUMBER_SETTINGS_POPUP = 'SET_NUMBER_SETTINGS_POPUP';
export const CLOSE_NUMBER_SETTINGS_POPUP = 'CLOSE_NUMBER_SETTINGS_POPUP';

export const POPUP_BLOCKCOUNTRIES_INFORMATION =
  'POPUP_BLOCKCOUNTRIES_INFORMATION';
export const POPUP_BLOCKNUMBERS_INFORMATION = 'POPUP_BLOCKNUMBERS_INFORMATION';
export const POPUP_BLOCK_PROMOTIONAL_CALLS = 'POPUP_BLOCK_PROMOTIONAL_CALLS';

export const POPUP_SIM_DEACTIVATION = 'POPUP_SIM_DEACTIVATION';

export const PAGE_LOGIN_TAB_LOGIN = 'login';
export const PAGE_LOGIN_TAB_DIRECT_LOGIN = 'directLogin';

export const PAGE_LOGIN_ERROR_EMAIL_LINKED_TO_MULTIPLE_ACCOUNTS =
  'email_linked_to_multiple_accounts';
export const PAGE_LOGIN_ERROR_EMAIL_IS_NO_LONGER_YOURS =
  'email_is_no_longer_yours';

export const OTP_FILL_DIGIT = 'OTP_FILL_DIGIT';
export const OTP_DELETE_DIGIT = 'OTP_DELETE_DIGIT';
export const GET_URL_PATH_FIRST_PART = 'GET_URL_PATH_FIRST_PART';
export const GET_URL_PATH_LAST_PART = 'GET_URL_PATH_LAST_PART';

export const VANITY_TAB_ALL_VANITIES = 'all_vanities';

export const COME_FROM_FLOW_DASHBOARD = 'dashaboard';

export const HISTORY_TAB_CONSUMPTION = 'consumption';
export const HISTORY_TAB_RECHARGE = 'recharge';
export const HISTORY_TAB_TRANSFER = 'transfer';

export const REQUEST_START = 'REQUEST_START';
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';
export const REQUEST_FAILED = 'REQUEST_FAILED';

export const HISTORY_TAB_CONSUMPTION_FILTER_CALLS = 'calls';
export const HISTORY_TAB_CONSUMPTION_FILTER_DATA = 'data';
export const HISTORY_TAB_CONSUMPTION_FILTER_SMS = 'sms';

export const HISTORY_TAB_RECHARGE_FILTER_DATA = 'data';
export const HISTORY_TAB_RECHARGE_FILTER_BALANCE = 'balance';

export const HISTORY_TAB_TRANSFER_FILTER_CREDIT = 'credit';
export const HISTORY_TAB_ALL_FILTERS = 'all';

export const TRANSFER_INCOMMING = 'incomming';
export const TRANSFER_OUTGOING = 'outgoing';

export const REQUEST_RECHARGE_HISTORY_START = 'REQUEST_RECHARGE_HISTORY_START';
export const REQUEST_RECHARGE_HISTORY_SUCCESS =
  'REQUEST_RECHARGE_HISTORY_SUCCESS';
export const REQUEST_RECHARGE_HISTORY_FAILED =
  'REQUEST_RECHARGE_HISTORY_FAILED';

export const REQUEST_TRANSFER_HISTORY_START = 'REQUEST_TRANSFER_HISTORY_START';
export const REQUEST_TRANSFER_HISTORY_SUCCESS =
  'REQUEST_TRANSFER_HISTORY_SUCCESS';
export const REQUEST_TRANSFER_HISTORY_FAILED =
  'REQUEST_TRANSFER_HISTORY_FAILED';

export const RECHARGE_HISTORY_ACTIVE_FILTER = 'RECHARGE_HISTORY_ACTIVE_FILTER';
export const LOAD_MORE = 'LOAD_MORE';
export const RESET_FILTER = 'RESET_FILTER';
export const REGISTRATION_FLOW = 'registration';
export const SUBSCRIBE_BUNDLE_FLOW = 'subscribeBundle'
export const RESET_PASSWORD = 'resetpassword'
export const QUICKLOGIN = 'quicklogin'
export const DEACTIVATENUMBER = 'deactivateNumber'
export const REFERAL_DATA = 'REFERAL_DATA'
export const LAYOUT_DEFAULT = 'default';
export const LAYOUT_WITH_HEADER_FOOTER = 'withoutHeaderFooter';
export const ORDER_NOT_FOUND = -902;
export const SORT_ASCENDING = 'SORT_ASCENDING';
export const SORT_DESCENDING = 'SORT_DESCENDING';

export const VAS_DEFAULT_MSG = 'VAS_DEFAULT_MSG';
export const VAS_CONFIRMATION_MSG = 'VAS_CONFIRMATION_MSG';
export const VAS_START_MSG = 'VAS_START_MSG';
export const VAS_FAILED_MSG = 'VAS_FAILED_MSG';
export const VAS_SUCCESS_MSG = 'VAS_SUCCESS_MSG';
export const VAS_SET_SERVICE_ID = 'VAS_SET_SERVICE_ID';
export const VAS_CLOSE_MODAL = 'VAS_CLOSE_MODAL';
export const VAS_SHOW_CONFIRMATION = 'VAS_SHOW_CONFIRMATION';

export const FETCH_CHART_HISTORY_START = 'FETCH_CHART_HISTORY_START';
export const FETCH_CHART_HISTORY_SUCCESS = 'FETCH_CHART_HISTORY_SUCCESS';
export const FETCH_CHART_HISTORY_FAILED = 'FETCH_CHART_HISTORY_FAILED';
export const MB_THRESHOLD = 100;
export const CUSTOM_BUNDLE_DATA = 'data';

export const CUSTOM_BUNDLE_MB = 'generic_lbl_unit_mb';
export const CUSTOM_BUNDLE_GB = 'generic_lbl_unit_gb';
export const CHART_DURATION_DAYS = 30;
export const HISTORY_RECORDS_PER_PAGE = 50;

export const POSTPAID_STEP_BILLS_LIST = 'bills_list';
export const POSTPAID_SET_INVOICE_DETAILS = 'set_invoice_details';
export const POSTPAID_STEP_INVOICE_DETAILS = 'invoice_details';
export const POSTPAID_STEP_USAGE_DETAILS = 'usage_details';
export const POSTPAID_STEP_PAYMENT_SUMMARY = 'POSTPAID_STEP_PAYMENT_SUMMARY';
export const POSTPAID_STEP_PAYMENT = 'payment';

export const BILL_USAGE_FILTER_START = 'BILL_USAGE_FILTER_START';
export const BILL_USAGE_FILTER_FAILED = 'BILL_USAGE_FILTER_FAILED';
export const BILL_USAGE_FILTER_SUCCESS = 'BILL_USAGE_FILTER_SUCCESS';

export const BILL_USAGE_UPDATE_FILTER = 'BILL_USAGE_UPDATE_FILTER';

export const POSTPAID_ACTION_UPDATE_STEP = 'update_step';
export const POSTPAID_CALL_TYPE = 'time';
export const UPDATE_INVOICE_DOCUMENT = 'UPDATE_INVOICE_DOCUMENT';

export const GETTING_BILLS_START = 'GETTING_BILLS_START';
export const GETTING_BILLS_FAILED = 'GETTING_BILLS_FAILED';
export const GETTING_BILLS_SUCCESS = 'GETTING_BILLS_SUCCESS';

export const SET_ACTIVE_BILL = 'SET_ACTIVE_BILL';

export const GETTING_BILL_INFO = 'GETTING_BILL_INFO';
export const BILLS_WINDOW = 'BILLS_WINDOW';

export const BILLS_PAYMENT_COMITING_START = 'BILLS_PAYMENT_COMITING_START';
export const BILLS_PAYMENT_COMITING_FAILED = 'BILLS_PAYMENT_COMITING_FAILED';
export const BILLS_PAYMENT_COMITING_SUCCESS = 'BILLS_PAYMENT_COMITING_SUCCESS';


export const HAWAKOM_SLIDE = 'SlideHawakom';
export const POSTPAID_SLIDE = 'SlidePostpaid';

// ** bundle filter :
export const GET_FILTER = 'GET_FILTER';
export const GET_FILTER_SUCCESS = 'GET_FILTER_SUCCESS';
export const GET_FILTER_FAILED = 'GET_FILTER_FAILED';
export const FILTER_BUNDLE_SCREEN = 'FILTER_BUNDLE_SCREEN';
export const FILTER_BUNDLE_RESULT_SCREEN = 'FILTER_BUNDLE_RESULT_SCREEN';
export const GET_BUNDLES = 'GET_BUNDLES';
export const GET_BUNDLES_SUCCESS = 'GET_BUNDLES_SUCCESS';
export const GET_BUNDLES_FAILED = 'GET_BUNDLES_FAILED';
export const BACK_TO_FILTER_SCREEN = 'BACK_TO_FILTER_SCREEN';
export const SELECT_FILTER = 'SELECT_FILTER';
export const REMOVE_SELECT_FILTER = 'REMOVE_SELECT_FILTER';
export const REMOVE_SECTION_FILTER = 'REMOVE_SECTION_FILTER';
export const RESET_ALL_SECTION_FILTER = 'RESET_ALL_SECTION_FILTER';
export const BUNDLE_DESCENDING = 'BUNDLE_DESCENDING';
export const BUNDLE_ASCENDING = 'BUNDLE_ASCENDING';
export const RESET_BUNDLE_SORT = 'RESET_BUNDLE_SORT';