import DashboardService from 'services/dashboard.service';
import { getUserPlans } from 'services/user.service';
import * as actions from './actionsType';
import { handleError } from '../../util';

const getDashboardSuccess = (data) => {
  console.log('data getDashboardSuccess', data);
  return {
    type: actions.DASHBOARD_SUCCESS,
    proprties: data.proprties,
    balance: data.balance,
    consumption: data.consumption,
    additional_widgets: data.additional_widgets,
  };
};

export const getDashboard = () => {
  return (dispatch) => {
    dispatch({ type: actions.DASHBOARD_START });
    DashboardService.getDashboard()
      .then((response) => {
        dispatch(getDashboardSuccess(response.data));
      })
      .catch((error) => {
        dispatch({
          type: actions.DASHBOARD_FAILED,
          errorMsg: handleError(error),
        });
      });
  };
};

const getMyPlansSuccess = (data) => {
  return {
    type: actions.USER_PLANS_SUCCESS,
    userPlans: data,
  };
};

export const getMyPlans = () => {
  return (dispatch) => {
    getUserPlans()
      .then((response) => {
        dispatch(getMyPlansSuccess(response.data));
      })
      .catch((error) => {
        dispatch({
          type: actions.USER_PLANS_FAILED,
          errorMsg: handleError(error),
        });
      });
  };
};

export const initialUserDashboardSuccess = (
  dashboardResponse,
  plansResponse
) => {
  return {
    type: actions.INITIAL_USER_DASHBOARD_SUCCESS,
    proprties: dashboardResponse.proprties,
    balance: dashboardResponse.balance,
    consumption: dashboardResponse.consumption,
    additional_widgets: dashboardResponse.additional_widgets,
    userPlans: plansResponse,
    subscriptionType: dashboardResponse.proprties.subscription_type,
  };
};

////////////////////
export const initialUserDashboard = (subAccountId = null) => {
  return (dispatch) => {
    dispatch({ type: actions.INITIAL_USER_DASHBOARD_START });

    DashboardService.getDashboard()
      .then((dashboardResponse) => {
        getUserPlans()
          .then((plansResponse) => {
            dispatch(
              initialUserDashboardSuccess(
                dashboardResponse.data,
                plansResponse.data
              )
            );
          })
          .catch((error) => {
            dispatch({
              type: actions.USER_PLANS_FAILED,
              errorMsg: handleError(error),
            });
          });
      })
      .catch((error) => {
        dispatch({
          type: actions.DASHBOARD_FAILED,
          errorMsg: handleError(error),
        });
      });
  };
};

export const resetUserInfo = () => {
  return (dispatch) => {
    dispatch({ type: actions.USER_PLANS_RESET });
  };
};
