import { getTranslation } from './content.service';
import { setTranslationKeys } from 'stores/Layout/layoutActions';
import AR from '../translation/ar.json';
import EN from '../translation/en.json';
const store = require('stores/RootReducer/rootReducer');

class Translation {
  static lang;
  static getTranslation() {
    return getTranslation();
  }

  static loadBackupTranslations(lang) {
    console.log('Using backup translations');
    import('../translation/' + lang + '.json').then((keys) => {
      store.default.dispatch(setTranslationKeys(keys));
    });
  }

  static endsWithPattern = (url, pattern) => {
    const urlLength = url.length;
    const patternLength = pattern.length;
    if (urlLength < patternLength) return false;
    return url.substring(urlLength - patternLength - 1) === pattern;
  };

  static getLanguage = (url) => {
    if (
      url.indexOf('-en') !== -1 ||
      url.indexOf('-english') !== -1 ||
      url.indexOf('/en/') !== -1 ||
      this.endsWithPattern(url, '/en')
    ) {
      this.lang = 'en';
      return 'en';
    } else if (
      url.indexOf('/') !== -1 ||
      url.indexOf('-ar') !== -1 ||
      url.indexOf('-arabic') !== -1 ||
      url.indexOf('/ar/') !== -1 ||
      this.endsWithPattern(url, '/ar')
    ) {
      this.lang = 'ar';
      return 'ar';
    }
  };

  static key = (k) => {
    if (store?.default?.getState()?.layout?.translation?.[k])
      return store.default.getState().layout.translation[k];
    // return k;
    return window.appLang === 'ar' || window.appLang === undefined
      ? AR[k]
      : EN[k];
  };
}
export default Translation;
