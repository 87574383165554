import * as actions from './actionsType';
import { fetchingMainSection, getPageContentV3 } from 'services/content.service';
import { getPackages } from 'services/offers.service';
import { handleError } from '../../util'

export const fetchHomepageSlider = () => {
  return dispatch => {
    dispatch({ type: actions.FETCHING_HOMEPAGE_SLIDER_START })
    fetchingMainSection()
      .then(response => {
        dispatch({ type: actions.FETCHING_HOMEPAGE_SLIDER_SUCCESS, sliders: response.data.result })
      })
      .catch(error => {
        dispatch({ type: actions.FETCHING_HOMEPAGE_SLIDER_FAILED, errorMsg: handleError(error) })
      })

  };
};

export const fetchHomepagePackages = () => {
  return dispatch => {
    dispatch({ type: actions.FETCHING_HOMEPAGE_PACKAGES_START })
    const featured = true;
    getPackages(featured)
      .then(response => {
        let packagesData = response.data.map(item => {
          let data = {
            id: item.id,
            img: item.desktop_image,
            url: item.url,
            title: item.title,
            desc: item.details,
            price:{ ...item.price }
          };
          return data;
        });

        dispatch({ type: actions.FETCHING_HOMEPAGE_PACKAGES_SUCCESS, packages: packagesData })
      })
      .catch(error => {
        dispatch({ type: actions.FETCHING_HOMEPAGE_PACKAGES_FAILED, errorMsg: handleError(error) })
      })

  };
};

export const fetchHomepageSections = () => {
  return dispatch => {
    dispatch({ type: actions.FETCHING_HOMEPAGE_SECTIONS_START })
    getPageContentV3('homepage')
      .then(response => {
        dispatch({ type: actions.FETCHING_HOMEPAGE_SECTIONS_SUCCESS, sections: response.data.result.sections })
      })
      .catch(error => {
        dispatch({ type: actions.FETCHING_HOMEPAGE_SECTIONS_FAILED, errorMsg: handleError(error) })
      })

  };
};